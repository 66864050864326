import axios from "axios";
import { config } from "../config";
import useRefreshToken from "../hooks/useRefreshToken";

const authService = axios.create({
  baseURL: config.auth_api_url,
  headers: {
    "Content-type": "application/json",
  },
});

authService.interceptors.request.use((axiosConfig) => {
  // Do something before request is sent
  let token = localStorage.getItem("accessToken");
  if (token) {
    axiosConfig.headers["Authorization"] = "Bearer " + token;
  }
  return axiosConfig;
});

authService.interceptors.response.use(response => response,
    async (error) => {
        const refresh = useRefreshToken();
        const prevRequest = error?.config;
        if(error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken?.accessToken}`;
            return authService(prevRequest);
        }
        return Promise.reject(error);
    }
);

/********************************/

export const updateUser = async (dataForUpdate) => {
  return await authService
    .patch(`/users/`, JSON.stringify(dataForUpdate))
    .then((resp) => {
      console.log(resp.data); // good data here
      return resp.data; // and returned
    });
};
