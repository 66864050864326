import { config } from "../config";
import axios from "axios";
import useRefreshToken from "../hooks/useRefreshToken";

const API_URL = config.java_api_url;
const API_URL_AUTH = config.auth_api_url;

const apiService = axios.create({
    baseURL: config.java_api_url,
    headers: {
        "Content-type": "application/json",
    },
});

apiService.interceptors.request.use((axiosConfig) => {
    // Do something before request is sent
    let token = localStorage.getItem("accessToken");
    if (token) {
        axiosConfig.headers["Authorization"] = "Bearer " + token;
    }
    return axiosConfig;
});

apiService.interceptors.response.use(response => response,
    async (error) => {
        const refresh = useRefreshToken();
        const prevRequest = error?.config;
        if(error?.response?.status === 401 && !prevRequest?.sent) {
            prevRequest.sent = true;
            const newAccessToken = await refresh();
            prevRequest.headers["Authorization"] = `Bearer ${newAccessToken?.accessToken}`;
            return apiService(prevRequest);
        }
        return Promise.reject(error);
    }
);

export const changeUserName = async (body) => {
    return await apiService
        .put(`/users/${body.userNameId}`, body.userNameFields)
        .then((resp) => {
            return resp.data;
        });
};

// export const changeUserName = (accessToken, body) => {
//     console.log(body.userNameId)
//   return fetch(`${API_URL}/users/${body.userNameId}`, {
//     method: "PUT",
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${accessToken}`,
//     },
//     body: JSON.stringify(body.userNameFields),
//   })
//       .then((res) => (res.ok ? res : Promise.reject(res)))
//       .then((res) => {
//         if (res.ok) {
//           return res.json();
//         }
//       })
//       .then((data) => data)
//       .catch((err) => {
//         throw new Error(err?.message || "Неизвестная ошибка");
//       });
// };

export const changeUserPassword = (accessToken, body) => {
  return fetch(`${API_URL}/users/${body.userNameId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      password: body.password,
    }),
  })
      .then((res) => (res.ok ? res : Promise.reject(res)))
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.status === 500) {
          throw new Error("Сервер временно недоступен");
        } else {
          throw new Error(err?.message || "Неизвестная ошибка");
        }
      });
};

export const updateUser = async (
    accessToken,
    {
      id = '',
      email = "",
      phone = "",
      password = "",
      confirmationCode = "",
      firstName = "",
      secondName = "",
      lastName = "",
    }
) => {
  return await fetch(`${API_URL_AUTH}/users/`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      id,
      email,
      phone,
      password,
      confirmationCode,
      firstName,
      secondName,
      lastName,
    }),
  })
      .then((res) => (res.ok ? res.json() : res))

      .then((data) => {
        return data;
      })
      .catch((err) => {
        if (err.status === 500) {
          throw new Error("Сервер временно недоступен");
        }
      });
};

export const getCodeChangePhoneEmail = async (value, type) => {
  return (
      fetch(
          `${API_URL_AUTH}/confirmation-code/delivery_type=${type}@value=${value}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
      )
          // .then((res) => (res.ok ? res : Promise.reject(res)))
          .then((res) => res)
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            return res;
          })
          .then((data) => {
            return data;
          })
          .catch((err) => {
            console.error(err);
            return;
            throw new Error(err.message);
          })
  );
};